import Alpine from 'alpinejs';

interface OpenGuideWindowData {
  url: string,
  location: string,
  term: string,
}

const getSelectParam = (value: string): [string, string]|null => {
  const nameValue = value.split('=');
  return nameValue.length === 2 ? nameValue as [string, string] : null;
}

Alpine.magic('openSftPage', () => ({
  url,
  location,
  term,
}: OpenGuideWindowData) => {
  const urlWithTrailingSlash = !url.endsWith('/') ? `${url}/` : url;
  if (!location && !term) {
    window.open(urlWithTrailingSlash, '_self');
    return;
  }
  const params = new URLSearchParams();
  const select1Param = getSelectParam(location);
  if (select1Param) {
    params.set(...select1Param);
  }
  const select2Param = getSelectParam(term);
  if (select2Param) {
    params.set(...select2Param);
  }
  window.open(`${urlWithTrailingSlash}?${params.toString()}`, '_self');
});

Alpine.magic('resetSft', () => {
  Array
    .from(document.querySelectorAll<HTMLAnchorElement>('a.search-filter-reset'))
    .forEach((elem) => elem.click())
});
