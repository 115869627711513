import Alpine, { Alpine as AlpineInstance } from 'alpinejs';

import './alpine/alpine-magic';
import './alpine/alpine-store';

declare global {
  interface Window { Alpine: AlpineInstance; }
}

window.Alpine = Alpine;

Alpine.start();
