import Alpine from 'alpinejs';

import {
  STORE_MOBILE_MENU,
  STORE_FILTER_MENU,
  STORE_SF_LOADING,
  STORE_SF_RESULTS_COUNT,
} from './alpine-constants';
import {ToggleStore} from './alpine-types';

const createToggleStore = (initialValue = false): ToggleStore => ({
  value: initialValue,
  toggle() {
    this.value = !this.value;
  },
  setTrue() {
    this.value = true;
  },
  setFalse() {
    this.value = false;
  },
});

Alpine.store(STORE_MOBILE_MENU, createToggleStore());
Alpine.store(STORE_FILTER_MENU, createToggleStore());
Alpine.store(STORE_SF_LOADING, createToggleStore());

const createNumberStore = (initialValue = 0) => ({
  value: initialValue,
  setValue(newValue: number) {
    this.value = newValue;
  }
});

Alpine.store(STORE_SF_RESULTS_COUNT, createNumberStore());
